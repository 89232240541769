<template>
    <div>
        <div class="panel-heading has-text-weight-bold">
            <div class="columns">

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Paid</p>
                    <p class="is-size-6"> {{ totalPaidExpenses | formatCurrency }}</p>
                    </div>
                </div>   

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Unpaid</p>
                    <p class="is-size-6"  :class="{'has-text-danger': (totalUnpaidExpenses)  > 0.00 }"> {{ totalUnpaidExpenses | formatCurrency }}</p>
                    </div>
                </div> 

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total</p>
                    <p class="is-size-6"> {{ totalExpenses | formatCurrency }}</p>
                    </div>
                </div>   

            </div>
        </div>
            <b-table class="control" 
                :data='expenses'
                :paginated="isPaginated"
                :per-page="perPage"
                :current.sync="page"
                backend-pagination
                backend-sorting
                :backend-filtering="backendFiltering"
                :debounce-search="searchStartMilliSecs"
                :total="totalRecords"
                paginationPosition="bottom"
                sortIcon="arrow-up"
                sortIconSize="is-small"
                default-sort="sale.closingDate"
                :row-class="getRowClass"
                defaultSortDirection='desc'
                @page-change="onPageChange"
                @sort="onColumnSort"
                @filters-change="filtersChanged"
                >

            <template>
                <b-table-column class="is-2 has-text-right" field="Edit" label="Edit" v-slot="props">
                    <b-button  v-if="allowEdit(props.row)" class="is-primary is-small"  v-on:click="doAction(props.row)" outlined rounded><i class="far fa-edit"></i></b-button>
                </b-table-column> 

                <b-table-column field="sale.closingDate" label="Date" sortable sorticon  v-slot="props">
                    {{ props.row.shiftDate | formatDate }}
                </b-table-column>

                <b-table-column field="vendorName" label="Vendor/Employee" sortable sorticon  v-slot="props">
                   {{ toTitleCase(props.row.vendorName) }}{{ toTitleCase(props.row.employeeName) }}
                </b-table-column>

                <b-table-column class="is-capitalized" field="expenseTypeName,deptMapping" label="Type" sortable sorticon  v-slot="props">
                    {{ toTitleCase(props.row.expenseTypeName) }}<span  v-if="props.row.expenseTypeId == 2"> - {{ getDeptMappingName(props.row.deptMapping) }}</span>
                </b-table-column>

                <b-table-column field="payments.method" label="Method" sortable sorticon  v-slot="props">
                    {{ props.row.payments[0].method }}
                </b-table-column>

                <b-table-column field="payments.status" label="Status" sortable sorticon  v-slot="props">
                    {{ props.row.payments[0].status }}
                </b-table-column>

                <b-table-column field="description" label="Ref/Desc" sortable sorticon  v-slot="props">
                    {{ toTitleCase(props.row.description) }}
                </b-table-column>

                <b-table-column field="payments.referenceNumber" label="CK#" sortable sorticon v-slot="props">
                    {{ props.row.payments[0].referenceNumber }} 
                </b-table-column>                

                <b-table-column field="payments.expectedClearingDate" label="Expected Date"  sortable sorticon v-slot="props">
                    {{ props.row.payments[0].expectedClearingDate | formatDate }}
                </b-table-column>
                <b-table-column field="payments.clearedOn" label="Cleared Date" sortable sorticon v-slot="props">
                    {{ props.row.payments[0].clearedOn | formatDate }}
                </b-table-column>

                <b-table-column numeric field="fuelVolume" label="Vol/Hrs" sortable sorticon  v-slot="props">
                    <span v-if="props.row.deptMapping=='Fuel'">{{ props.row.fuelVolume }} gal.</span> 
                    <span v-if="props.row.expenseTypeName=='Payroll'">{{ props.row.employeePayrollHours }} hrs.</span>
                </b-table-column>

                <b-table-column numeric field="amount" label="Amount" sortable sorticon  v-slot="props">
                    {{ props.row.amount | formatCurrency }}
                </b-table-column>

            </template>
   
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

        </b-table>
        <expense-edit-form
                :storeId="storeId"
                :tempExpense="tempExpense"
                :isShowEditDialog="isShowEditDialog" 
                @hide-dialog="cancelEditRecord"
                @save-record="saveEditRecord">
        </expense-edit-form>        
        <div class="panel-heading has-text-weight-bold">
            <div class="columns">

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Paid</p>
                    <p class="is-size-6"> {{ totalPaidExpenses | formatCurrency }}</p>
                    </div>
                </div>   

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Unpaid</p>
                    <p class="is-size-6"> {{ totalUnpaidExpenses | formatCurrency }}</p>
                    </div>
                </div> 

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total</p>
                    <p class="is-size-6"> {{ totalExpenses | formatCurrency }}</p>
                    </div>
                </div>   

            </div>
        </div>
    </div>
</template>

<script>

import DataLoading from '../../../../components/app/DataLoading'
import ExpenseEditForm from './ExpenseEditForm.vue'
import UserStore from "../../../../store/UserStore"
import axios from 'axios'
import moment from 'moment'

export default {

    props: {
        expenses: Array,
        storeId: Number,
        isPaginated: Boolean,
        perPage: Number,
        isLoading: Boolean,
        isFullPage: Boolean,
        showExpenseType: String,
        backendFiltering: Boolean,
        page: Number,
        totalRecords: Number,
        totalExpenses: Number,
        totalUnpaidExpenses: Number,
        totalPaidExpenses: Number,
        search: Object
    },

    data() {
        return {
            expense: {},
            tempExpense: {
                "payments": []
            },
            fuelInvoices: [],
            isShowEditDialog: false,
            searchStartMilliSecs: 1000,
            storeMappings: UserStore.getters.getStoreMappings,
        }
    },

    components: {
        DataLoading,
        ExpenseEditForm
    },

    computed: {

        totalFuelVolume() {
            var fuelInvoices = this.expenses.filter((expense) => expense.deptMapping=='Fuel')
            return fuelInvoices.reduce((accumulator, item) => accumulator + item.fuelVolume,0,);
        },

        totalFuelInvoice() {
            var fuelInvoices = this.expenses.filter((expense) => expense.deptMapping=='Fuel')
            return fuelInvoices.reduce((accumulator, item) => accumulator + item.amount,0,);
        },

        totalInventory() {
            var inventoryItems = this.expenses.filter((expense) => expense.expenseTypeId == 2 && expense.deptMapping != 'Fuel')
            return inventoryItems.reduce((accumulator, item) => accumulator + item.amount,0,);
        },
        
    },

    methods: {
        
        getRowClass(row) {
            switch (row.payments[0].status) {
                case "Open":
                    return 'has-background-warning-light'
                case "NSF":
                    return 'has-background-danger-light'
                default:
                    return 'has-background-default'
            }           
        },

        getDeptMappingName(deptMapping) {
            var deptMap = this.storeMappings.find(item => item.value === deptMapping)
            if (deptMap) {
                return deptMap.name
            }
            return ""
        },
        
        doAction(row) {

            this.expense = row
            this.tempExpense = JSON.parse(JSON.stringify(row))
            this.tempExpense.deptMappingName = this.getDeptMappingName(row.deptMapping)
            this.tempExpense.payments[0].expectedClearDateObj = moment(this.tempExpense.payments[0].expectedClearingDate).toDate()
            if (this.tempExpense.payments[0].clearedOn != null) {
                this.tempExpense.payments[0].clearedOnObj = moment(this.tempExpense.payments[0].clearedOn).toDate()
            }
            
            this.toggleDialog()

        },

        allowEdit(row) {
            if (row.payments[0].method !='Cash' && row.payments[0].status !='Paid') {
                return true
            } else {
                return false
            }
        },

        toggleDialog() {
            this.isShowEditDialog = ! this.isShowEditDialog
        },   
        
        onPageChange(page) {
            this.$emit('page-changed', page)
        },

        onColumnSort(field,order) {
            // if (field == "shiftDate") {
            //     field = "expenseDateFrom"
            // }
            this.$emit('column-sort', field, order)
        },

        filtersChanged(event) {
            this.$emit('filters-changed',event)
        },
        
        cancelEditRecord() {
            this.toggleDialog()
        },

        saveEditRecord() {
            this.updateExpense()
            this.toggleDialog()
        },        

        updateExpense() {
                const url = process.env.VUE_APP_API_ROOT_URL + '/payments' 
                axios.put(url,
                    this.tempExpense.payments[0],
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        
                        this.$emit('fetch-records')
                        this.$buefy.toast.open({
                                message: 'Expense Payment Status Updated!',
                                type: 'is-success'
                            })
                    })
                    .catch( (error) => {
                        if (error.response.status === 400) {
                            
                            this.$buefy.toast.open({
                                message: 'Failed to update expense payment status' + error.message,
                                type: 'is-warning'
                            })
                        }
                    }) 

        },
    }
}
</script>