<template>
<div class="section">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowEditDialog }">
        <div class="modal-background"></div>
        <div class="modal-card scrollable" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Edit Expense Payment Status</p>&nbsp;&nbsp;
                <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
            </header>
            <section class="modal-card-body"  v-if="tempExpense.payments[0]">
                <div class="columns is-mobile">
                    <div class="column">
                        Expense Date:
                    </div>
                    <div class="column">
                        {{ tempExpense.shiftDate | formatDate }}
                    </div>                    
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        Vendor/Employee:
                    </div>
                    <div class="column">
                        {{ tempExpense.vendorName}}{{ tempExpense.employeeName }}
                    </div>                    
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        Expense Type:
                    </div>
                    <div class="column">
                        {{ tempExpense.expenseTypeName}} 
                        <span v-if="tempExpense.deptMappingName"><br>{{ tempExpense.deptMappingName }}</span>
                    </div>                    
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        Payment Type:
                    </div>
                    <div class="column">
                        {{ tempExpense.payments[0].method}}
                    </div>                    
                </div>               
                <div class="columns is-mobile">
                    <div class="column">
                        Amount:
                    </div>
                    <div class="column">
                        {{ tempExpense.amount | formatCurrency}}
                    </div>                    
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        Check/Ref#:
                    </div>
                    <div class="column" >
                        <b-input v-model="tempExpense.payments[0].referenceNumber"  v-if="tempExpense.payments[0].status !== 'Cleared'">

                        </b-input>
                        <div v-else>
                            {{ tempExpense.payments[0].referenceNumber}}
                        </div>
                    </div>                    
                </div>                 
                <div class="columns is-mobile">
                    <div class="column">
                        Expected to Clear:
                    </div>
                    <div class="column">
                        <b-field v-if="tempExpense.payments[0].status !== 'Cleared'">
                            <b-datepicker 
                                :mobile-native="isMobileNativeDatePicker"
                                :min-date="minClearDate"
                                position="is-top-left"
                                size="is-small"
                                placeholder="Date..."
                                required                            
                                v-model="tempExpense.payments[0].expectedClearDateObj">
                            </b-datepicker>                            
                        </b-field>
                        <div v-else>
                            {{ tempExpense.payments[0].expectedClearingDate | formatDate}}
                        </div>
                    </div>                    
                </div>

                <div class="columns is-mobile">
                    <div class="column">
                        Payment Status:
                    </div>
                    <div class="column">
                        <div class="block">
                            <b-radio v-model="tempExpense.payments[0].status"
                                name="Open"
                                native-value="Open"
                                @input="processStatusChange">
                                Open
                            </b-radio>
                            <br>
                            <b-radio v-model="tempExpense.payments[0].status"
                                name="NSF"
                                native-value="NSF"
                                @input="processStatusChange"
                                >
                                NSF
                            </b-radio>
                            <br>
                            <b-radio v-model="tempExpense.payments[0].status"
                                name="Cleared"
                                native-value="Cleared"
                                @input="processStatusChange"
                                >
                                Cleared
                            </b-radio>                                                        
                        </div>
                    </div>                    
                </div> 
                <div class="columns is-mobile" v-if="tempExpense.payments[0].status == 'Cleared'">
                    <div class="column">
                        Payment Cleared Date:
                    </div>
                    <div class="column">
                        <b-field>
                            <b-datepicker 
                                :mobile-native="isMobileNativeDatePicker"
                                :min-date="minClearDate"
                                :max-date="maxClearDate"
                                position="is-top-left"
                                size="is-small"
                                placeholder="Date..."
                                required
                                v-model="tempExpense.payments[0].clearedOnObj">
                            </b-datepicker>                            
                        </b-field> 
                        
                    </div>
                </div>
            
            </section>
            <footer class="modal-card-foot">
            <button class="button is-success" 
                    type="button" 
                    @click="submitForm" 
                    :disabled='isSaveDisabled'>Save</button>
            <button class="button" type="button" @click="closeDialog">Cancel</button>
            <span class='is-danger'></span>
            </footer>

        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        isShowEditDialog: Boolean,
        tempExpense: {},
    },

    data() {
        return {
            isPrinting: false,
            showNumberControls: true,
            isMobileNativeDatePicker: false,
            maxClearDate: new Date(),
        }
    },

    methods: {
        closeDialog() {

            this.$buefy.dialog.confirm({
                title: 'Leaving without saving!',
                message: 'Are you sure you want to discard all changes and leave?',
                confirmText: 'Discard Changes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.$emit('hide-dialog')
                }
            })            
            
        },

        processStatusChange() {
            // if (this.tempExpense.payments[0].status == 'Cleared') {
            //     this.tempExpense.payments[0].clearedOnObj = Date()
            // }
            // if (this.tempExpense.payments[0].clearedOn != null) {
            //     // this.tempExpense.payments[0].clearedOnObj = date()
            // } else {
            //     this.tempExpense.payments[0].clearedOnObj = Date()
            // }
            
        },

        submitForm() {
            if (this.tempExpense.payments[0].expectedClearDateObj) {
                this.tempExpense.payments[0].expectedClearingDate = this.tempExpense.payments[0].expectedClearDateObj.toISOString().split('T')[0]
            }
            if (this.tempExpense.payments[0].status != 'Cleared') {
                this.tempExpense.payments[0].clearedOn = null
            } else {
                this.tempExpense.payments[0].clearedOn = this.tempExpense.payments[0].clearedOnObj.toISOString().split('T')[0]
                                
            }           
            this.$emit('save-record')
        },

    },

    computed: {
        isSaveDisabled() {
            if (!this.tempExpense.payments[0]) {
                return true
            }
            if (this.tempExpense.payments[0].status == 'Cleared' && !this.tempExpense.payments[0].clearedOnObj ) {
                return true
            }
            return false
        },

        minClearDate() {
            return new Date(this.tempExpense.shiftDate)
        },

    }

 
}
</script>
