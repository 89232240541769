<template>
<div class="section">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>

</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../../store/UserStore"

export default {
    props: {
        isShowPDFDialog: Boolean,
        showExpenseType: String,
        dateFilter: String,
        filteredRecords: Array,
        dateRange: Array,
        search: Object
    },

    data() {
        return {
            // sortedRecords: [],
            isShowPDFDialogLocal: false,
            filterText: "",
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'})
        }

    },

    computed: {

        totalPaidExpenses() {
            var paidExpense = this.filteredRecords.filter((item) => (item.payments[0].status == 'Paid' || item.payments[0].status == 'Cleared') )
            return paidExpense.reduce((accum,item) => accum + parseFloat(item.amount), 0)

        },

        totalUnpaidExpenses() {
            return this.totalExpenses - this.totalPaidExpenses
        },

        totalFuelVolume() {
            var fuelInvoices = this.filteredRecords.filter((expense) => expense.deptMapping=='Fuel')
            return fuelInvoices.reduce((accumulator, item) => accumulator + item.fuelVolume,0,);
        },

        totalFuelInvoice() {
            var fuelInvoices = this.filteredRecords.filter((expense) => expense.deptMapping=='Fuel')
            return fuelInvoices.reduce((accumulator, item) => accumulator + item.amount,0,);
        },

        totalInventory() {
            var inventoryItems = this.filteredRecords.filter((expense) => expense.expenseTypeId == 2 && expense.deptMapping!='Fuel')
            return inventoryItems.reduce((accumulator, item) => accumulator + item.amount,0,);
        },

        totalExpenses() {
            return this.filteredRecords.reduce((accum,item) => accum + parseFloat(item.amount), 0)
        },

        
    },    

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        createPdf: async(gthis) => {

            const pdfDoc = await PDFDocument.create();
            var page = pdfDoc.addPage(PageSizes.Letter);
            var pageNumber = 1

            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

            await gthis.pageHeader(page,helveticaFont)
            await gthis.veecliLogo(pdfDoc, page)
            var row = 670
            var textWidth = 0
            var rowCount = 0
            var text = ""

            if (gthis.filterText != "") {
                row -= 30
            }

            gthis.filteredRecords.forEach(function(item){

                if (rowCount% 35 == 0 && rowCount > 0) {
                    gthis.pageFooter(pdfDoc, page, pageNumber)
                    pageNumber++

                    page = pdfDoc.addPage(PageSizes.Letter)
                    gthis.pageHeader(page,helveticaFont)

                    // Set Line to start records
                    row = 670
                    if (gthis.filterText != "") {
                        row -= 30
                    }

                }

                page.drawText(moment(item.shiftDate).format('MM/DD/YYYY'), { 
                    x: 20, 
                    y: row, 
                    size: 10, 
                    })

                switch (item.expenseTypeName) {
                    case "Fuel":
                        page.drawText(gthis.toTitleCase(item.vendorName).substr(0,20), { 
                            x: 75, 
                            y: row, 
                            size: 10, 
                            })                        
                        page.drawText(item.fuelVolume, { 
                            x: 165, 
                            y: row, 
                            size: 10,
                            TextAlignment: 2
                            })
                        break

                    case "Inventory":
                        page.drawText(gthis.toTitleCase(item.vendorName).substr(0,17), { 
                            x: 75, 
                            y: row, 
                            size: 10, 
                            })

                        page.drawText(("Inventory - "+item.deptMapping).substr(0,17), { 
                            x: 165, 
                            y: row, 
                            size: 10,
                            TextAlignment: 0
                            })
                        break 
                        
                    case "Payroll":
                        if (item.employeeName == null) item.employeeName = ""
                        page.drawText(gthis.toTitleCase(item.employeeName.substring(0, 17)), { 
                            x: 75, 
                            y: row, 
                            size: 10, 
                            })
                            
                        page.drawText("Payroll", { 
                            x: 165, 
                            y: row, 
                            size: 10,
                            TextAlignment: 2
                            })
                        break
                        
                    default:
                        page.drawText(gthis.toTitleCase(item.vendorName).substr(0,17), { 
                            x: 75, 
                            y: row, 
                            size: 10, 
                            })

                        page.drawText(gthis.toTitleCase(item.expenseTypeName).substring(0, 17), { 
                            x: 165, 
                            y: row, 
                            size: 10, 
                            }) 
                        break                                        
                }

                // if (item.payments.length > 0) {
                //     page.drawText(item.payments[0].status+"/"+item.payments[0].method, { 
                //         x: 240, 
                //         y: row, 
                //         size: 10, 
                //         })
                // }

                text = ""

                switch (item.payments[0].status) {
                    case "Cleared":
                        text = "Cleard"
                        break
                    case "Paid":
                        text = "Paid"
                        break
                    case "Open":
                        text = "Open"
                        break                                                
                }

                if (item.payments.length > 0) {

                    switch (item.payments[0].method) {
                        case "Check":
                            text += "-Check"
                            break
                        case "Cash":
                            text += "-Cash"
                            break
                        case "EFT":
                            text += "-EFT"
                            break                                                
                    }
                }
                
                page.drawText(text, { 
                    x: 250, 
                    y: row, 
                    size: 10, 
                    })
                            

                text = item.description.substr(0,5)
                if (item.payments[0].referenceNumber) {
                    text = text + " / " + item.payments[0].referenceNumber
                }

                page.drawText(gthis.toTitleCase(text), { 
                    x: 320, 
                    y: row, 
                    size: 10, 
                    })                 

                // page.drawText(gthis.toTitleCase(item.payments[0].referenceNumber).substr(0,5), { 
                //     x: 340, 
                //     y: row, 
                //     size: 10, 
                //     }) 

                text = (item.payments[0].expectedClearingDate ? moment(item.payments[0].expectedClearingDate).format('MM/DD/YYYY') : " ")
                page.drawText(text, { 
                    x: 375, 
                    y: row, 
                    size: 10, 
                    }) 
                 

                text = (item.payments[0].clearedOn ? moment(item.payments[0].clearedOn).format('MM/DD/YYYY') : " ")
                page.drawText(text, { 
                    x: 430, 
                    y: row, 
                    size: 10, 
                    })                     

                text = (item.fuelVolume ? item.fuelVolume :  '').toString()
                if (text) {
                    text = text + " gal."
                }
                textWidth = helveticaFont.widthOfTextAtSize(text, 10)
                page.drawText(text, { 
                    x: 530-textWidth, 
                    y: row, 
                    size: 10, 
                    })

                text = (item.employeePayrollHours ? item.employeePayrollHours :  '').toString()
                if (text) {
                    text = text + " hrs."
                }
                textWidth = helveticaFont.widthOfTextAtSize(text, 10)
                page.drawText(text, { 
                    x: 530-textWidth, 
                    y: row, 
                    size: 10, 
                    })

                textWidth = helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(item.amount), 10)
                
                page.drawText(gthis.dollarUSLocale.format(item.amount), { 
                        x: 590-textWidth, 
                        y: row, 
                        size: 10,
                        TextAlignment: 0,
                        })          

                row -= 3
                page.drawLine({
                    start: { x: 10, y: row },
                    end: { x: 600, y: row },
                    thickness: .5,
                    opacity: 0.20,
                })                 

                row -= 15
                rowCount++

            });

            if (row < 50) {
                    gthis.pageFooter(pdfDoc, page, pageNumber)
                    pageNumber++

                    page = pdfDoc.addPage(PageSizes.Letter)
                    gthis.pageHeader(page,helveticaFont)

                    // Set Line to start records
                    row = 650                
            }

            gthis.pageTotal(page,row-3)
            gthis.pageFooter(pdfDoc,page,pageNumber)

            const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        pageHeader(page,helveticaFont) {

            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = helveticaFont.widthOfTextAtSize(storeName, 15);

            page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 15, 
                })

            var reportHeading = this.showExpenseType + " Expenses Report"
            textWidth = helveticaFont.widthOfTextAtSize(reportHeading, 13)

            page.drawText(reportHeading, { 
                x: 300 - (textWidth/2), 
                y: 730, 
                size: 13,
                TextAlignment: 1 
                })

            row = 710
            var dateRange = moment(this.dateRange[0]).format('MM/DD/YYYY') + " - " + moment(this.dateRange[1]).format('MM/DD/YYYY')
            textWidth = helveticaFont.widthOfTextAtSize(dateRange, 11);

            page.drawText(dateRange, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 11, 
                })

            var row = 690
                
            this.filterText = ""
            if (this.search.vendorId > 0) {
                this.filterText += "   Vendor: " + this.search.vendorName
            }
            if (this.search.expenseTypeId > 0) {
                this.filterText += "   Expense Type: " + this.search.expenseTypeName
            }
            if (this.search.employeeId > 0) {
                this.filterText += "   Employee: " + this.search.employeeName
            }
            if (this.search.deptMapping != "") {
                this.filterText += "   Department: " + this.search.deptMapping
            }
            if (this.filterText != "") {
                // filterText = "Applied Filters: " + filterText
                textWidth = helveticaFont.widthOfTextAtSize(this.filterText, 11);
                page.drawText(this.filterText, { 
                    x: 300 - (textWidth/2), 
                    y: row, 
                    size: 11,
                    TextAlignment: 2
                })
                row -= 30
            }                

            // Print Column Heading
            page.drawText('Date', { 
                x: 20, 
                y: row, 
                size: 10,
                TextAlignment: 0 
                }) 

            switch (this.showExpenseType) {
                case "Fuel":
                    page.drawText('Vendor', { 
                        x: 75, 
                        y: row, 
                        size: 10,
                        TextAlignment: 0 
                        })                        
                    page.drawText('Volume', { 
                        x: 165, 
                        y: row, 
                        size: 10,
                        TextAlignment: 2
                        })
                    break

                case "Inventory":
                    page.drawText('Vendor', { 
                        x: 75, 
                        y: row, 
                        size: 10,
                        TextAlignment: 0 
                        })

                    page.drawText('Department', { 
                        x: 165, 
                        y: row, 
                        size: 10,
                        TextAlignment: 0
                        })
                    break 
                    
                case "Payroll":
                    page.drawText('Employee', { 
                        x: 75, 
                        y: row, 
                        size: 10,
                        TextAlignment: 0 
                        })                    
                    page.drawText('Type', { 
                        x: 165, 
                        y: row, 
                        size: 10,
                        TextAlignment: 2
                        })
                    break
                default:
                    page.drawText('Vendor', { 
                        x: 75, 
                        y: row, 
                        size: 10,
                        TextAlignment: 0 
                        })                    
                    page.drawText('Dept./Type', { 
                        x: 165, 
                        y: row, 
                        size: 10,
                        TextAlignment: 0 
                        }) 
                    break                                        
            }

            page.drawText('Method', { 
                x: 250, 
                y: row, 
                size: 10,
                TextAlignment: 0 
                })


            page.drawText('Ref/Ck#', { 
                x: 320, 
                y: row, 
                size: 10,
                TextAlignment: 0 
                }) 

            // page.drawText('Check#', { 
            //     x: 340, 
            //     y: row, 
            //     size: 10,
            //     TextAlignment: 0 
            //     }) 

            page.drawText('Expected', { 
                x: 375, 
                y: row, 
                size: 10,
                TextAlignment: 0 
                })                

            page.drawText('Cleared', { 
                x: 430, 
                y: row, 
                size: 10,
                TextAlignment: 0 
                })   

            page.drawText('Hrs./Vol.', { 
                x: 490, 
                y: row, 
                size: 10,
                TextAlignment: 0 
                }) 

            page.drawText('Amount', { 
                x: 555, 
                y: row, 
                size: 10,
                TextAlignment: 2
                })

            row -= 4
            page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })                
                
        },

        veecliLogo: async(pdfDoc, page) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                // opacity: 0.75,
            })

            page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })                    
        },

        pageFooter(pdfDoc, page, pageNumber) {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

            page.drawText('Page ' + pageNumber, { 
                x: 540, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

            // this.veecliLogo(pdfDoc, page)
        },
        
        pageTotal(page,row) {

            page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })  
            row -= 15

            page.drawText('Paid', { 
                x: 20, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText(this.dollarUSLocale.format(this.totalPaidExpenses), { 
                x: 20, 
                y: row-20, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Unpaid', { 
                x: 90, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText(this.dollarUSLocale.format(this.totalUnpaidExpenses), { 
                x: 90, 
                y: row-20, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Expense', { 
                x: 160, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText(this.dollarUSLocale.format(this.totalExpenses - this.totalFuelInvoice - this.totalInventory), { 
                x: 160, 
                y: row-20, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Inventory', { 
                x: 230, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText(this.dollarUSLocale.format(this.totalInventory), { 
                x: 230, 
                y: row-20, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Fuel Invoice', { 
                x: 320, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText(this.dollarUSLocale.format(this.totalFuelInvoice), { 
                x: 320, 
                y: row-20, 
                size: 11,
                TextAlignment: 2
                })                

            page.drawText('Fuel Volume', { 
                x: 420, 
                y: row, 
                size: 11,
                TextAlignment: 2
                }) 

            page.drawText(this.totalFuelVolume+" gal.", { 
                x: 420, 
                y: row-20, 
                size: 11,
                TextAlignment: 2
                }) 

            page.drawText('Total', { 
                x: 520, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText(this.dollarUSLocale.format(this.totalExpenses), { 
                x: 520, 
                y: row-20, 
                size: 11,
                TextAlignment: 2
                })

            row -= 30
            page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            }) 


            // var filterText = " "
            // if (this.search.vendorId > 0) {
            //     filterText += "   Vendor: " + this.search.vendorName
            // }
            // if (this.search.expenseTypeId > 0) {
            //     filterText += "   Expense Type: " + this.search.expenseTypeName
            // }
            // if (this.search.employeeId > 0) {
            //     filterText += "   Employee: " + this.search.employeeName
            // }
            // if (this.search.deptMapping != "") {
            //     filterText += "   Department: " + this.search.deptMapping
            // }
            // if (filterText != " ") {
            //     // filterText = "Applied Filters: " + filterText
            //     row -= 20
            //     page.drawText(filterText, { 
            //         x: 40, 
            //         y: row, 
            //         size: 11,
            //         TextAlignment: 2
            //     }) 
            // }

                       
        },

        async loadData() {
            // this.$emit('get-print-data')
            // console.log("Loading", this.filteredRecords)
            // this.sortedRecords = this.filteredRecords.sort(function(a, b){    
            //     return (a.shiftDate)-(b.shiftDate)
            // })
            this.isShowPDFDialogLocal = true
            await this.createPdf(this)
        }
    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>