<template>
    <section class="section">
        <div class="content has-text-grey has-text-centered">
            <b-loading :is-full-page="isFullPage" :active.sync="isLoading" v-if="isLoading">
                <b-icon
                    pack="fas"
                    icon="sync-alt"
                    size="is-large"
                    custom-class="fa-spin">
                </b-icon>
            </b-loading>
            <div v-else>
                <b-icon
                    icon="sad-cry"
                    size="is-large">
                </b-icon>
                <p v-if="message">{{ message }}</p>
                
                <p v-if="!message">No Data Received.</p>
            </div>                    
        </div>
    </section>
</template>
<script>
export default {
    props: {
        isLoading: Boolean,
        isFullPage: Boolean,
        message: String
    },
}
</script>