<template>
    <section class="container is-fullhd">
        <b-tabs type="is-boxed">
            <b-tab-item label="Month" icon="calendar" v-if="showMonthTab">
                <b-field grouped>
                    <b-datepicker
                        custom-class="month-date-selector"
                        ref="monthSelector"
                        aria-hidden=""
                        type="month"
                        v-model="month"
                        :disabled="disabled"
                        :inline="isMobileDevice"
                        placeholder="Select Month..."
                        :min-date = "minDate"
                        :max-date = "maxDate"
                        icon="calendar"
                        icon-clickable
                        @icon-click="$refs.rangeSelector.toggle()"                        
                        @input="setMonthDates"
                        :date-formatter="monthFormatter"
                        trap-focus>
                    </b-datepicker>
                </b-field>
            </b-tab-item>                
            <b-tab-item label="Date Range" icon="clone" v-if="showDateRangeTab">
                <b-field grouped>
                    <b-datepicker :mobile-native="false"
                        custom-class="month-date-selector"
                        ref="rangeSelector"
                        placeholder="Select Date Range..."
                        v-model="dateRange" 
                        :disabled="disabled"
                        @input="setDateRange"   
                        :min-date = "minDate"
                        :max-date = "maxDate"
                        icon="clone"
                        icon-clickable
                        @icon-click="$refs.rangeSelector.toggle()"                    
                        range>
                    </b-datepicker>
                </b-field>
            </b-tab-item>                             
        </b-tabs>
    </section>
</template>

<script>

import moment from 'moment'

export default {
    props: {
        parentDateRange:  [],
        showDateRangeTab: {
            type: Boolean,
            default: true,
        },
        showMonthTab: {
            type: Boolean,
            default: true,
        },
        disabled: { type: Boolean, default: false}
    },

    data() {
        return {
            dateRange: [],
            minDate: new Date(2021, 0, 1),
            maxDate: new Date(),
            month: new Date(),
            isMobileDevice: this.mobileCheck(),
        }
    },

    methods: {
        setMonthDates() {
            var startDate = (new Date(this.month.getFullYear(), this.month.getMonth(), 1))
            var lastDay = (new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0))
            if (lastDay > this.maxDate) {
                lastDay = this.maxDate
            }               
            this.dateRange = [startDate, lastDay]    
            this.$emit('set-date-range', [this.dateRange[0], this.dateRange[1]])
        },

        setDateRange() {
            this.$emit('set-date-range', [this.dateRange[0], this.dateRange[1]])
        },

        monthFormatter(date) {
            return moment(date).format('MMMM YYYY')
        },
        
    },

    watch: {
        // parentDateRange: function() {
        //     this.dateRange = this.parentDateRange
        // }

    }, 

    mount() {
        
    }

}
</script>

<style >
.month-date-selector {
    color: brown;
    font-weight: 900;
    font-size-adjust: 0.6;
}
/* .month-date-selector input[type=text]  {
    
    border-color: rgba(64, 221, 21, 0.724);
    width: 280px;
} */
</style>